import { AnyAction, ThunkMiddleware, type Middleware } from '@reduxjs/toolkit'
import {
  BillingExternalFragment,
  InsuranceOrdinality,
  type RootStateWithPatientAuth,
  patientAuthActions,
  patientRegistrationApi,
  patientRegistrationExternalApi,
} from '@valerahealth/rtk-query'

import {
  reducerPath,
  actions,
  type AppState,
  AppActionPayloads,
  InsuranceVerificationState,
  InsuranceVerification,
  type SetVerificationStateItem,
  getVerificationKey,
} from './appSlice'

export const InsuranceOrdinalityArr = [
  InsuranceOrdinality.Primary,
  InsuranceOrdinality.Secondary,
  InsuranceOrdinality.Tertiary,
]

type RootState = {
  [reducerPath]: AppState
} & RootStateWithPatientAuth & {
    [patientRegistrationApi.reducerPath]: ReturnType<
      typeof patientRegistrationApi.reducer
    >
    [patientRegistrationExternalApi.reducerPath]: ReturnType<
      typeof patientRegistrationExternalApi.reducer
    >
  }

export const appMiddleware: Middleware<AnyAction, RootState> =
  ({ dispatch, getState }) =>
  (next) =>
  (
    action: ReturnType<
      (typeof patientAuthActions)[keyof typeof patientAuthActions]
    >,
  ) => {
    next(action)
    const { returnUrl, returnType } = getState().app.onboarding
    // add the jwt to the return url when it updates
    if (
      action.type === 'patientAuth/setAuthState' &&
      action.payload &&
      returnUrl &&
      returnType === 'mobile'
    ) {
      dispatch(
        // add the JWT to the mobile app return url so that the user can authenticate once back in the app
        actions.setReturnUrl({
          // if query params are already set on the returnUrl we want to replace them
          returnUrl: `${returnUrl.split('?')[0]}?${new URLSearchParams({
            jwt: action.payload,
          })}`,
          returnType,
        }),
      )
    }
  }

export const parsePatientBilling = (
  insuranceVerificationState: InsuranceVerificationState,
  patientBilling: BillingExternalFragment | null | undefined,
  isFinalCheck: boolean,
) => {
  const loading = Object.entries(insuranceVerificationState).filter(
    (v): v is [string, InsuranceVerification] => v[1]?.status === 'loading',
  )

  const updates = loading
    .map(([key]): SetVerificationStateItem | null => {
      const match = patientBilling?.insurances?.find(
        (v) =>
          getVerificationKey({
            insurancePlanId: v.insurancePlanId ?? '',
            idNumber: v.idNumber ?? '',
          }) === key,
      )

      const { isActive } = match?.eligibilityVerification || {}

      if (typeof isActive === 'boolean')
        return {
          key,
          state: {
            status: isActive ? 'active' : 'fail',
            billingInsured: match,
          },
        }
      return isFinalCheck
        ? {
            key,
            state: {
              // fallback to pending status
              status: 'pending',
            },
          }
        : null
    })
    .filter((v): v is SetVerificationStateItem => !!v)

  return {
    remainingLoading: loading.length - updates.length,
    updates,
  }
}

let pollStartTs: number | undefined
let subscription:
  | ReturnType<
      ReturnType<
        typeof patientRegistrationExternalApi.endpoints.loadPatientBillingExternal.initiate
      >
    >
  | undefined

export const insuranceVerificiationMiddleware: ThunkMiddleware<
  RootState,
  AnyAction
> =
  ({ getState, dispatch }) =>
  (next) =>
  async (action: AppActionPayloads) => {
    next(action)

    // when we got a new pending verification, reset the polling subscription to patient billing
    if (
      action.type === 'app/setVerificationState' &&
      action.payload.some((v) => v.state.status === 'loading')
    ) {
      if (!subscription) {
        subscription = dispatch(
          patientRegistrationExternalApi.endpoints.loadPatientBillingExternal.initiate(
            undefined,
            {
              forceRefetch: true,
              subscribe: true,
              subscriptionOptions: { pollingInterval: 2000 },
            },
          ),
        )
      } else {
        subscription.updateSubscriptionOptions({ pollingInterval: 2000 })
      }
      pollStartTs = Date.now()
    }

    // process load patient billing success
    if (
      patientRegistrationExternalApi.endpoints.loadPatientBillingExternal.matchFulfilled(
        action,
      )
    ) {
      // stop polling after 15 seconds
      const isFinalCheck =
        (pollStartTs || 0) + 15000 < action.meta.fulfilledTimeStamp

      const { remainingLoading, updates } = parsePatientBilling(
        getState()[reducerPath].onboarding.insuranceVerificationState,
        action.payload.loadPatientBillingExternal,
        isFinalCheck,
      )

      if (updates.length) {
        dispatch(actions.setVerificationState(updates))
      }

      if (!remainingLoading) {
        subscription?.updateSubscriptionOptions({ pollingInterval: 0 })
      }
    }

    // process load patient billing failure
    if (
      patientRegistrationExternalApi.endpoints.loadPatientBillingExternal.matchRejected(
        action,
      )
    ) {
      // stop polling after 15 seconds
      const isFinalCheck = (pollStartTs || 0) + 15000 < Date.now()

      if (isFinalCheck) {
        subscription?.updateSubscriptionOptions({ pollingInterval: 0 })
        const loadingVerifications = Object.entries(
          getState()[reducerPath].onboarding.insuranceVerificationState,
        ).filter(
          (v): v is [string, InsuranceVerification] =>
            v[1]?.status === 'loading',
        )
        if (loadingVerifications.length) {
          dispatch(
            actions.setVerificationState(
              loadingVerifications.map((entry): SetVerificationStateItem => {
                const [key, value] = entry
                return {
                  key,
                  state: {
                    ...value,
                    status: 'pending',
                  },
                }
              }),
            ),
          )
        }
      }
    }
  }
