import { patientRegistrationExternalApi } from '@valerahealth/rtk-query'
import {
  actions,
  getVerificationKey,
  SetVerificationStateItem,
} from 'store/appSlice'

export const patientRegistrationExternalEnhancedApi =
  patientRegistrationExternalApi.enhanceEndpoints({
    endpoints: {
      updatePatientSubmission: {
        onQueryStarted: async ({ input }, { dispatch, queryFulfilled }) => {
          try {
            const { data } = await queryFulfilled
            // backend kicked off a new verification
            const insuranceOrdinality =
              data.updatePatientRegistrationSubmission.metaData
                ?.pendingVerificationOrdinalities || []
            const payload = insuranceOrdinality
              .map((ordinal): SetVerificationStateItem | null => {
                const billingInsured = input.billing?.insurances?.find(
                  (v) => v.type === ordinal,
                )
                if (!billingInsured) return null
                return {
                  key: getVerificationKey({
                    idNumber: billingInsured.idNumber ?? '',
                    insurancePlanId: billingInsured.insurancePlanId ?? '',
                  }),
                  state: {
                    status: 'loading',
                  },
                }
              })
              .filter((v): v is SetVerificationStateItem => !!v)
            if (payload.length) {
              dispatch(
                actions.setVerificationState(
                  payload.filter((v): v is SetVerificationStateItem => !!v),
                ),
              )
            }
          } catch (e) {
            console.error(e)
          }
        },
      },
    },
  })
