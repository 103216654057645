import { Tabs, Tab, SxProps } from '@mui/material'
import { useTranslation } from '@valerahealth/ui-translation'

type Props = {
  languageOptions: { key: string; label: string }[]
  sx?: SxProps
}

export default function LanguageSelect({ languageOptions, sx }: Props) {
  const { i18n, t } = useTranslation()
  return (
    <Tabs
      aria-label={t('language')}
      value={i18n.resolvedLanguage}
      onChange={(e, value) => {
        i18n.changeLanguage(value)
      }}
      sx={{
        minHeight: [0, '3rem'],
        '& .MuiTab-root': {
          padding: ['.5rem', '.75rem 1rem'],
          minHeight: [0, '3rem'],
          minWidth: 0,
        },
        ...sx,
      }}
    >
      {languageOptions.map(({ label, key }) => (
        <Tab label={label} key={key} value={key} />
      ))}
    </Tabs>
  )
}

export type LanguageSelectProps = Props
