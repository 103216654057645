import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { createApi } from '@reduxjs/toolkit/query/react'
import { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes'
import { api as endpoints } from './generated'
import {
  PATIENT_AUTH_REDUCER_KEY,
  type RootStateWithPatientAuth,
} from '../../patientAuth/patientAuthSlice'

const api = createApi({
  reducerPath: 'patientRegistrationApiExternal',
  tagTypes: ['BillingExternal'],
  keepUnusedDataFor: 600,
  baseQuery: graphqlRequestBaseQuery({
    url: `https://${process.env.PATIENT_REGISTRATION_API_DOMAIN}/graphql`,
    customErrors: (error) => {
      console.error(error.response)
      const e = error.response.errors?.[0] as any
      const response: ErrorResponse = {
        name: e?.errorType || '',
        message: e?.message || '',
        stack: error.stack || '',
      }
      return response
    },
    prepareHeaders: (headers, { getState, endpoint }) => {
      switch (endpoint) {
        case 'getExternalPracticeStates':
          headers.set(
            'x-api-key',
            process.env.PATIENT_REGISTRATION_EXTERNAL_API_KEY,
          )
          break
        default: {
          const accessToken = (getState() as RootStateWithPatientAuth)[
            PATIENT_AUTH_REDUCER_KEY
          ].session?.access_token
          if (accessToken) {
            headers.set('Authorization', `Bearer ${accessToken}`)
          }
        }
      }

      return headers
    },
  }),
  endpoints,
}).enhanceEndpoints({
  endpoints: {
    loadPatientBillingExternal: {
      keepUnusedDataFor: 0, // always refetch insurance verification
      providesTags: (res, err, req) => {
        return [
          {
            type: 'BillingExternal',
            id: res?.loadPatientBillingExternal?.id,
          },
        ]
      },
    },
  },
})


export { api }
