import { api } from './patientRegistrationExternalApiService'

const patientRegistrationExternalReducer = {
  [api.reducerPath]: api.reducer,
}

export {
  api as patientRegistrationExternalApi,
  patientRegistrationExternalReducer,
}
