import { lazy, Suspense } from 'react'
import { Routes, Route, useMatch } from 'react-router-dom'
import { TranslationProvider } from '@valerahealth/ui-translation'
import {
  Box,
  Typography,
  CenteredSpinner,
  Notifications,
} from '@valerahealth/ui-components'
import Page from 'layout/Page'
import { T_NAMESPACE, translationProps } from 'locales'

import {
  patientOnboardingFormBasePath,
  referralFormBasePath,
} from './routeConfig'

// Users will almost never go from one route to another so it makes sense to split the code from here

const PatientFiles = lazy(
  () => import(/* webpackChunkName: "PatientFiles" */ './PatientFiles'),
)
const PatientSelfOnboarding = lazy(
  () =>
    import(
      /* webpackChunkName: "PatientSelfOnboarding" */ './PatientSelfOnboarding'
    ),
)
const PatientReferralOnboarding = lazy(
  () =>
    import(
      /* webpackChunkName: "PatientReferralOnboarding" */ './PatientReferralOnboarding'
    ),
)

export default function Router() {
  const matchesReferralForm = useMatch(`${referralFormBasePath}/*`)
  return (
    <TranslationProvider
      {...translationProps}
      defaultNS={
        matchesReferralForm
          ? T_NAMESPACE.patientReferralForm
          : T_NAMESPACE.forms
      }
    >
      <Routes>
        <Route element={<Page />}>
          <Route
            path={process.env.FORMS_UI_PATIENT_FILES_ROUTE}
            element={
              <Suspense
                fallback={
                  <CenteredSpinner circularProgressProps={{ size: 50 }} />
                }
              >
                <PatientFiles />
              </Suspense>
            }
          />
          <Route
            path={`${patientOnboardingFormBasePath}/*`}
            element={
              <Suspense
                fallback={
                  <CenteredSpinner circularProgressProps={{ size: 50 }} />
                }
              >
                <PatientSelfOnboarding />
              </Suspense>
            }
          />
          <Route
            path={`${referralFormBasePath}/*`}
            element={
              <Suspense
                fallback={
                  <CenteredSpinner circularProgressProps={{ size: 50 }} />
                }
              >
                <PatientReferralOnboarding />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh',
                  width: '100vw',
                }}
              >
                <Typography variant="h3">404</Typography>
              </Box>
            }
          />
        </Route>
      </Routes>
      <Notifications />
    </TranslationProvider>
  )
}
