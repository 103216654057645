/* eslint no-param-reassign:0 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BillingInsuredExternalFragment } from '@valerahealth/rtk-query'
import {
  type PatientOnboardingFormRoutePath,
  type ReferralFormRoutePath,
} from '../routes/routeConfig'

// keep consistent and make human readable
export const getVerificationKey = ({
  idNumber,
  insurancePlanId,
}: {
  idNumber: string
  insurancePlanId: string
}) => `InsurancePlanId#${insurancePlanId}_MemberNumber#${idNumber}`

export type OptionalOnboardingFormSection = Exclude<
  PatientOnboardingFormRoutePath | ReferralFormRoutePath,
  | '*'
  | 'referrar-information'
  | 'progress/patient-information'
  | 'progress/patient-information/verify'
  | 'complete'
>

export type InsuranceVerificationStatus =
  //| 'initial' // set when there was insurance submitted
  | 'loading' // set when we get a pending insurance ordinality in the response and are polling billing to see the result
  | 'active' // set when billing indicates ins active
  | 'fail' // set when billing indicates ins inactive
  | 'pending' // fallback, requires manual verification

export type InsuranceVerification = {
  status: InsuranceVerificationStatus
  billingInsured?: BillingInsuredExternalFragment | null
}

export type InsuranceVerificationState = {
  // ultimately, verification is based on only two datapoints... Insurance ID and Member ID. If a user supplies the two we can display
  [verificationKey: string]: InsuranceVerification
}

export type AppState = {
  onboarding: {
    returnUrl?: string | null
    returnType?: 'mobile' | 'external' | null
    submissionId?: string | null
    captchaToken?: string | null
    optionalOnboardingFormSections?: OptionalOnboardingFormSection[]
    insuranceVerificationState: InsuranceVerificationState
  }
}

export const defaultInsuranceVerificationState: InsuranceVerificationState = {}

const initialState: AppState = {
  onboarding: {
    insuranceVerificationState: defaultInsuranceVerificationState,
  },
}

export type SetVerificationStateItem = {
  key: string
  state: InsuranceVerification
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setReturnUrl: (
      state,
      {
        payload,
      }: PayloadAction<{
        returnUrl: string | null
        returnType?: AppState['onboarding']['returnType']
      }>,
    ) => {
      state.onboarding.returnUrl = payload.returnUrl
      state.onboarding.returnType = payload.returnType
    },
    setOptionalSections: (
      state,
      { payload }: PayloadAction<OptionalOnboardingFormSection[]>,
    ) => {
      state.onboarding.optionalOnboardingFormSections = payload
    },
    setSubmissionId: (state, { payload }: PayloadAction<string | null>) => {
      state.onboarding.submissionId = payload
    },
    setCaptchaToken: (state, { payload }: PayloadAction<string | null>) => {
      state.onboarding.captchaToken = payload
    },
    setVerificationState: (
      state,
      { payload }: PayloadAction<SetVerificationStateItem[]>,
    ) => {
      for (const { key, state: payloadItem } of payload) {
        const verificationState = state.onboarding.insuranceVerificationState
        const currentVerificationStateItem = verificationState[key]
        // set to null or set with status
        verificationState[key] = {
          ...currentVerificationStateItem,
          ...payloadItem,
        }
      }
    },
  },
})

export const { actions, reducer, name: reducerPath } = appSlice

export type AppActionPayloads = ReturnType<
  (typeof actions)[keyof typeof actions]
>

export default appSlice
