import { ReactNode } from 'react'
import { Box, SxProps } from '@mui/system'

export type PageProps = {
  topNav: ReactNode | ReactNode[]
  sideBar: ReactNode | ReactNode[]
  children: ReactNode | ReactNode[]
  height?: string
  styles?: { content: SxProps }
}

export default function Page({
  topNav,
  sideBar,
  children,
  height = '100vh',
  styles,
}: PageProps) {
  return (
    <Box display="flex" height={height}>
      {sideBar}
      <Box
        display="flex"
        flexWrap="nowrap"
        flexDirection="column"
        flexGrow="1"
        overflow="auto"
      >
        {topNav}
        <Box
          flexGrow="1"
          display="flex"
          flexDirection="column"
          gap={2}
          padding={2}
          paddingTop={1}
          sx={styles?.content}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}
