import { createRoot } from 'react-dom/client'
import App from 'App'
import { datadogLogs } from '@datadog/browser-logs'

const { PROJECT_DATADOG_CLIENT_TOKEN, APP_PACKAGE_NAME, APP_VERSION, ENV } =
  process.env

if (ENV === 'TST' || ENV === 'PRD') {
  datadogLogs.init({
    clientToken: PROJECT_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: APP_PACKAGE_NAME,
    env: ENV.toLowerCase(),
    version: `v${APP_VERSION}`,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  })
}

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(<App />)
} else {
  console.error('Failed to find the root element.')
}
