import { type Middleware } from '@reduxjs/toolkit'

import {
  type RootStateWithPatientAuth,
  actions,
  ExternalAccessTokenClaim,
} from './patientAuthSlice'
import { parseJwt } from '../../utils'

let timeout: null | NodeJS.Timeout = null
const setAuthTimeout = (cb: () => void, exp: number) => {
  timeout = setTimeout(cb, exp * 1000 - Date.now())
}

export const patientAuthMiddleware: Middleware<{}, RootStateWithPatientAuth> =
  ({ dispatch }) =>
  (next) =>
  (action: ReturnType<(typeof actions)[keyof typeof actions]>) => {
    next(action)
    if (action.type === 'patientAuth/setAuthState') {
      const exp = parseJwt<ExternalAccessTokenClaim>(action.payload)?.exp
      if (exp)
        return setAuthTimeout(() => {
          // clear auth state at expiration
          dispatch(actions.setAuthState(null))
        }, exp)
      // If there is a timeout and we no longer are authenticated clear the timeout
      if (timeout) clearTimeout(timeout)
    }
  }
