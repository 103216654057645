/* eslint no-param-reassign:0 */
// import { type TypedUseSelectorHook, useSelector } from 'react-redux'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { parseJwt } from '../../utils'

export enum MatchTier {
  DIRECT = 'A',
  SOLE= 'B',
  NAME_AND_DOB_MATCHED= 'C',
  DOB_MATCHED= 'D',
  NAME_MATCHED= 'E',
  ARBITRARY= 'F',
  NEW= 'G'
}


/** the properties accessible in the decoded access token JWT */
export type ExternalAccessTokenClaim = {
  /** can fill in if we want, but no need to decode today */
  /** expiration epoch seconds */
  exp: number
  /** issued at time epoch seconds */
  iat: number
  /** issuer */
  iss: string
  /** confidence */
  tier: MatchTier
}

export type PatientAuthState = {
  /* null means auth in uninitialized */
  isAuthenticated: boolean | null
  session: {
    // JWT prefixed with prefixed with patext_ for "Patient External Token". If we ever need to decode the token we would strip that out before attempting jwt.decode.
    access_token: string
    /* expiration - seconds since epoch, to convert to date use new Date(exp * 1000) */
    exp: number
    // decoded jwt
    claim: ExternalAccessTokenClaim
  } | null
}

export const PATIENT_AUTH_REDUCER_KEY = 'patientAuth'

export type RootStateWithPatientAuth = {
  [PATIENT_AUTH_REDUCER_KEY]: PatientAuthState
}

const initialState: PatientAuthState = {
  isAuthenticated: null,
  session: null,
}

const authSlice = createSlice({
  name: PATIENT_AUTH_REDUCER_KEY,
  initialState,
  reducers: {
    setAuthState: (state, { payload }: PayloadAction<string | null>) => {
      const decoded = parseJwt<ExternalAccessTokenClaim>(payload)
      const isAuthenticated = !!decoded && decoded.exp * 1000 > Date.now()
      state.isAuthenticated = isAuthenticated
      if (isAuthenticated) {
        state.session = {
          exp: decoded.exp - 2, // build in 2 seconds of latency so that we dont start queries with a token that expires by the time a server processes it
          claim: decoded,
          access_token: payload!,
        }
      } else {
        state.session = null
      }
    },
  },
})

export const { actions, reducer, name: reducerPath } = authSlice

export type AuthActionPayloads = ReturnType<
  (typeof actions)[keyof typeof actions]
>
