import { maskPhoneNumber } from '@valerahealth/ui-components'

export const FORMATTED_CALL_CENTER_PHONE = maskPhoneNumber(
  process.env.VALERA_CALL_CENTER_PHONE_NUMBER,
)

export const FORMATTED_PATIENT_HELP_PHONE = maskPhoneNumber(
  process.env.VALERA_PATIENT_HELP_PHONE_NUMBER,
)

export const LANGUAGES = [
  'American Sign Language (ASL)',
  'Arabic',
  'Bengali',
  'Cantonese',
  'Chinese',
  'Creole',
  'Croatian',
  'Czech',
  'Danish',
  'Dutch',
  'English',
  'Finnish',
  'French',
  'Frysian',
  'German',
  'Greek',
  'Guyanese',
  'Haitian Creole',
  'Hebrew',
  'Hindi',
  'Italian',
  'Japanese',
  'Khmer',
  'Korean',
  'Mandarin',
  'Norwegian',
  'Patois',
  'Polish',
  'Portuguese',
  'Punjabi',
  'Romanian',
  'Russian',
  'Serbian',
  'Spanish',
  'Swahili',
  'Swedish',
  'Telegu',
  'Turkish',
  'Urdu',
  'Yiddish',
  'Yoruba',
]
