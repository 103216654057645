import { Navbar as BaseNavbar } from '@valerahealth/ui-components/layout'
import { Link,  Box } from '@valerahealth/ui-components/base'
import { LanguageSelect } from '@valerahealth/ui-components/features'
import { useTranslation } from '@valerahealth/ui-translation'
import { languages } from 'locales'
import { Route, Routes } from 'react-router'
export default function Navbar() {
  const { t } = useTranslation()

  const languageSelect = (
    <Box
      sx={{
        margin: '1rem auto 0 auto',
        maxWidth: (theme) => theme.breakpoints.values.md,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <LanguageSelect sx={{ margin: '0 1rem' }} languageOptions={languages} />
    </Box>
  )

  const navbar = (
    <BaseNavbar
      color="transparent"
      position="relative"
      elevation={0}
      toolbarProps={{
        sx: {
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        },
      }}
    >
      <Link
        to="https://valerahealth.com"
        target="_blank"
        sx={{
          width: '100%',
          height: 'auto',
          maxWidth: '15rem',
          pt: 2,
          pb: 2,
        }}
      >
        <img
          src="https://cdn.valerahealth.com/images/valera-health-logo-v2.svg"
          alt="Valera Health"
        />
      </Link>
    </BaseNavbar>
  )

  return (
    <Routes>
      <Route
        path="/patient-onboarding/*"
        element={
          <>
            {navbar}
            {languageSelect}
          </>
        }
      />
      <Route path="/patient-referral/*" element={navbar} />
      <Route
        path="/patient-files"
        element={
          <>
            {navbar}
            {languageSelect}
          </>
        }
      />
    </Routes>
  )
}
