import { useEffect, useState } from 'react'
import {
  UI_COMPONENTS_LOCALS_NAMESPACE,
  en as uiComponentsEn,
} from '@valerahealth/ui-components/locales'
import {
  type TranslationProviderPropsType,
  useTranslation,
} from '@valerahealth/ui-translation'
import localeEn from '@valerahealth/ui-translation/locales/en'
import en from './en'
import referralFormEn from './patientReferralForm/en'
import { Locale } from 'date-fns'

export enum Language {
  en = 'en',
  es = 'es',
  zhCHS = 'zh-CHS',
  zhTW = 'zh-TW',
}

export const languages = [
  {
    key: Language.en,
    label: 'English',
  },
  {
    key: Language.es,
    label: 'Español',
  },
  {
    key: Language.zhCHS,
    label: '简体中文',
  },
  {
    key: Language.zhTW,
    label: '繁體中文',
  },
]

const lngToLocale = {
  [Language.en]: undefined, // default,
  [Language.es]: () =>
    import('date-fns/locale/es').then(
      (res) => res.default,
    ) as unknown as Locale,
  [Language.zhCHS]: () =>
    import('date-fns/locale/zh-CN').then(
      (res) => res.default,
    ) as unknown as Locale,
  [Language.zhTW]: () =>
    import('date-fns/locale/zh-TW').then(
      (res) => res.default,
    ) as unknown as Locale,
}

const getLocale = async (ln: Language): Promise<Locale | undefined> => {
  return lngToLocale[ln]?.()
}

export const useDateFnsLocale = () => {
  const { i18n } = useTranslation()
  const lng = i18n.resolvedLanguage as Language
  const [locale, setLocale] = useState<Locale | undefined>(undefined)
  useEffect(() => {
    ;(async () => {
      setLocale(await getLocale(lng))
    })()
  }, [lng])
  return locale
}

export const T_NAMESPACE = {
  base: 'base',
  forms: 'forms',
  uiComponents: UI_COMPONENTS_LOCALS_NAMESPACE,
  patientReferralForm: 'patientReferralForm',
}

export const translationProps: Omit<TranslationProviderPropsType, 'children'> =
  {
    ns: Object.values(T_NAMESPACE),
    fallbackNS: [T_NAMESPACE.forms, T_NAMESPACE.base],
    staticResources: {
      en: {
        [T_NAMESPACE.base]: localeEn,
        [T_NAMESPACE.forms]: en,
        [T_NAMESPACE.patientReferralForm]: referralFormEn,
        ...uiComponentsEn,
      },
    },
    loadResources: (language, namespace) => {
      if (!(Object.values(Language) as string[]).includes(language)) return
      switch (namespace) {
        case T_NAMESPACE.forms:
          return import(`./${language}.ts`).then(
            ({ default: language }) => language,
          )
        case T_NAMESPACE.uiComponents:
          return import(
            `@valerahealth/ui-components/locales/${language}.js`
          ).then(({ default: language }) => language)
        case T_NAMESPACE.patientReferralForm:
          return import(`./patientReferralForm/${language}.ts`).then(
            ({ default: language }) => language,
          )
        case T_NAMESPACE.base:
        default:
          return import(
            `@valerahealth/ui-translation/locales/${language}.js`
          ).then(({ default: language }) => language)
      }
    },
  }
