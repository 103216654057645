import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { createApi } from '@reduxjs/toolkit/query/react'
import { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes'
import { createService } from '../../utils'
import { api as endpoints } from './generated'
import { actions } from './patientAuthSlice'

const patientAuthApiService = createService(({ origin }) => {
  const api = createApi({
    reducerPath: 'patientAuthApi',
    keepUnusedDataFor: 120,
    tagTypes: [],
    endpoints,
    baseQuery: graphqlRequestBaseQuery({
      url: `${origin}/graphql`,
      customErrors: (error) => {
        console.error(error.response)
        const e = error.response.errors?.[0] as any
        const response: ErrorResponse = {
          name: e?.errorType || '',
          message: e?.message || '',
          stack: error.stack || '',
        }
        return response
      },
      prepareHeaders: (headers) => {
        headers.set(
          'x-api-key',
          process.env.PATIENT_AUTH_EXTERNAL_API_KEY || '',
        )
        return headers
      },
    }),
  })

  api.enhanceEndpoints({
    endpoints: {
      // Save the response to auth state any time this mutation is run
      confirmOTPAndMatch: {
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const accessToken =
              (await queryFulfilled).data.confirmOTPAndMatch?.access_token ||
              null
            dispatch(actions.setAuthState(accessToken))
          } catch (e) {
            dispatch(actions.setAuthState(null))
          }
        },
      },
      // Save the response to auth state any time this mutation is run
      validateCaptchaAndMatch: {
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const accessToken =
              (await queryFulfilled).data.validateCaptchaAndMatch
                ?.access_token || null
            dispatch(actions.setAuthState(accessToken))
          } catch (e) {
            dispatch(actions.setAuthState(null))
          }
        },
      },
    },
  })
  return api
})

export const patientAuthApi = patientAuthApiService({
  origin: `https://${process.env.PATIENTAUTH_API_DOMAIN}`,
})

export type patientAuthAPIType = typeof patientAuthApi
