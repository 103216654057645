const ENABLE_REFERRALFORM_INSURANCESECTION =
  process.env.ENABLE_REFERRALFORM_INSURANCESECTION

export const referralFormRoutes = ENABLE_REFERRALFORM_INSURANCESECTION
  ? [
      { path: '*' as const }, // start
      { path: 'progress/referrer-information' as const },
      { path: 'progress/patient-information' as const },
      { path: 'progress/service-matching' as const },
      { path: 'progress/insurance' as const },
      { path: 'progress/finalize' as const },
      { path: 'complete' as const },
    ]
  : [
      { path: '*' as const }, // start
      { path: 'progress/referrer-information' as const },
      { path: 'progress/patient-information' as const },
      { path: 'progress/service-matching' as const },
      { path: 'complete' as const },
    ]

export type ReferralFormRoutePath = (typeof referralFormRoutes)[number]['path']

export const referralFormBasePath = '/patient-referral'

export const patientOnboardingFormRoutes = [
  { path: '*' as const }, // start
  { path: 'progress/patient-information' as const },
  { path: 'progress/patient-information/verify' as const },
  { path: 'progress/service-matching' as const },
  { path: 'progress/insurance' as const },
  { path: 'progress/insurance/verify' as const },
  { path: 'progress/finalize' as const },
  { path: 'complete' as const },
]

export type PatientOnboardingFormRoutePath =
  (typeof patientOnboardingFormRoutes)[number]['path']

export const patientOnboardingFormBasePath = '/patient-onboarding'
