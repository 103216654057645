import { Outlet } from 'react-router-dom'
import { Page as BasePage } from '@valerahealth/ui-components/layout'
import { Typography } from '@valerahealth/ui-components/base'
import Navbar from 'layout/Navbar'
import { getIniFrame } from '../components/onboardingForm/utils'

const { APP_VERSION } = process.env

export default function Page() {
  const iniFrame = getIniFrame()
  return (
    <BasePage topNav={!iniFrame ? <Navbar /> : null} sideBar={null}>
      <Outlet />

      <Typography
        variant="subtitle2"
        color="GrayText"
        sx={{
          textAlign: 'center',
          position: 'relative',
          height: '0',
          top: '-0.5em',
        }}
      >
        v.{APP_VERSION}
      </Typography>
    </BasePage>
  )
}
